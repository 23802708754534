import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import React from "react"
import Faq from "react-faq-component"
import GA from "../../components/ga"
import Hero from "../../components/hero"
import InnerWrapper from "../../components/innerWrapper"
import Layout from "../../components/layout"
import SEO from "../../SEO"
import styles from "../scss/about.module.scss"

function FAQ({ data }) {

  const formattedFaqs = data.allContentfulFaq.edges.map(faq => {
    return {
      title: faq.node.title,
      content: faq.node.text.text,
    }
  })

  const faqs = {
    title: "",
    rows: formattedFaqs,
  }

  return (
    <>
      <GA />
      <Layout>
        <SEO title="About - FAQ" />
        <Hero slogan={data.contentfulFaqPageHeroSlogan.slogan} cta={false} />
        <section className={styles.faq} id="about">
          <InnerWrapper width={1024}>
            <h2>FAQ</h2>
            {documentToReactComponents(data.contentfulFaqPage.text.json)}
            <Faq
              data={faqs}
              styles={{
                rowContentPaddingTop: "10px",
                rowContentPaddingBottom: "10px",
              }}
            />
          </InnerWrapper>
        </section>
      </Layout>

    </>
  )
}

export const query = graphql`
  query FaqQuery {
    contentfulFaqPageHeroSlogan {
      slogan
    }
    contentfulFaqPage {
      title
      text {
        json
      }
    }

    allContentfulFaq {
      edges {
        node {
          title
          text {
            text
          }
        }
      }
    }
  }
`

export default FAQ
